import type { ApolloQueryResult } from '@apollo/client';
import type { CachedTextQuery } from '@aurora/shared-generated/types/graphql-types';
import useTranslation from '../../useTranslation';
import { SharedComponent } from '../../../enums';

/**
 * Represents a language with its key and translation.
 * @property {string} key - The language key.
 * @property {string} translation - The translated name of the language.
 */
export interface Language {
  key: string;
  translation: string;
}

export interface LanguageText {
  /**
   * Return the language text for a given locale
   * @param language the language
   */
  getLanguageText: (language: string, localBundleOverride?: Record<string, string>) => string;
  /**
   * Maps an array of language keys to an array of Language objects with translations.
   */
  getLanguagesLabel: (languagesArray: string[]) => Language[];
  /**
   * Sorts an array of Language objects based on their translations in ascending order.
   */
  sortLanguages(languages: Language[]): Language[];
  /**
   * True when text is loading
   */
  loading: boolean;
  /**
   * Refetch the text
   */
  refetchLanguageText: (locale: string) => Promise<ApolloQueryResult<CachedTextQuery>>;
}

/**
 * Hook used to get and sort community languages.
 */
export function useLanguageText(): LanguageText {
  const { formatMessage, loading, refetch } = useTranslation(SharedComponent.USE_LANGUAGE_TEXT);

  function getLanguageText(language: string, localBundle: Record<string, string>): string {
    return formatMessage(`UseLanguageText.language.${language}.label`, {}, localBundle);
  }

  /**
   * Maps an array of language keys to an array of Language objects with translations.
   *
   * @param {string[]} languagesArray - An array of language keys.
   * @returns {Language[]} An array of Language objects with keys and translations.
   */
  function getLanguagesLabel(languagesArray: string[]): Language[] {
    return languagesArray.map((lang: string) => ({
      key: lang,
      translation: formatMessage(`UseLanguageText.language.${lang}.label`)
    }));
  }

  /**
   * Sorts an array of Language objects based on their translations in ascending order.
   *
   * @param {Language[]} languages - An array of Language objects to be sorted.
   * @returns {Language[]} A new array of Language objects sorted by translation.
   */
  function sortLanguages(languages: Language[]): Language[] {
    return languages.sort((a: Language, b: Language) => a.translation.localeCompare(b.translation));
  }

  return {
    getLanguageText,
    getLanguagesLabel,
    sortLanguages,
    loading,
    refetchLanguageText: refetch
  };
}
